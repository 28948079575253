define("accounting/index", ["exports", "accounting/format", "accounting/format-column", "accounting/format-money", "accounting/format-number", "accounting/parse", "accounting/settings", "accounting/to-fixed", "accounting/unformat", "accounting/version"], function (exports, _accountingFormat, _accountingFormatColumn, _accountingFormatMoney, _accountingFormatNumber, _accountingParse, _accountingSettings, _accountingToFixed, _accountingUnformat, _accountingVersion) {
  exports.format = _accountingFormat["default"];
  exports.formatColumn = _accountingFormatColumn["default"];
  exports.formatMoney = _accountingFormatMoney["default"];
  exports.formatNumber = _accountingFormatNumber["default"];
  exports.parse = _accountingParse["default"];
  exports.settings = _accountingSettings["default"];
  exports.toFixed = _accountingToFixed["default"];
  exports.unformat = _accountingUnformat["default"];
  exports.version = _accountingVersion["default"];
  exports["default"] = {
    format: _accountingFormat["default"],
    formatColumn: _accountingFormatColumn["default"],
    formatMoney: _accountingFormatMoney["default"],
    formatNumber: _accountingFormatNumber["default"],
    parse: _accountingParse["default"],
    settings: _accountingSettings["default"],
    toFixed: _accountingToFixed["default"],
    unformat: _accountingUnformat["default"],
    version: _accountingVersion["default"]
  };
});