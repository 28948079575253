define('ember-moment/helpers/now', ['exports', 'ember', 'moment', 'ember-moment/helpers/-base'], function (exports, _ember, _moment, _emberMomentHelpersBase) {
  exports['default'] = _emberMomentHelpersBase['default'].extend({
    moment: _ember['default'].inject.service(),

    compute: function compute() {
      this._super.apply(this, arguments);

      var momentService = this.get('moment');

      return momentService.moment(_moment['default'].now());
    }
  });
});